/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text, FullmapCover, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap className="--style2" name={"e9jd34g191i"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--center pb--50 pt--40" style={{"backgroundColor":"#f5f8fd"}} name={"ocpl270ihkd"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Kontakt"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"3l2zi8a56dj"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"Häusl&amp;Home Reality s.r.o.\n"}>
              </Title>

              <Text className="text-box w--500" style={{"maxWidth":650,"marginTop":34}} content={"+420 724 808 718\n"}>
              </Text>

              <Text className="text-box w--500" style={{"maxWidth":650,"marginTop":7}} content={"miroslav.hausl@hauslahome.cz\n\n"}>
              </Text>

              <Text className="text-box w--500" style={{"maxWidth":650,"marginTop":7}} content={"Ke kapslovně 2855/1 <br>Praha 3 PSČ 130 00\n\n\n"}>
              </Text>

              <Text className="text-box w--500" style={{"maxWidth":650,"marginTop":33}} content={"Facebook : Miroslav Häusl Finance &amp; Reality\n\n\n"}>
              </Text>

              <Text className="text-box w--500" style={{"maxWidth":650,"marginTop":9}} content={"Instagram: mirekhausl\n\n\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <FullmapCover place={"Ke kapslovně 2855/1  Praha 3 PSČ 130 00"} zoom={"13"}>
              </FullmapCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"k57myfoevo"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}